import React, { useState } from 'react';
import axios from 'axios';
import '../styles/ContactUs.css';
import useAnalytics from '../hooks/useAnalytics';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        email: '',
        message: ''
    });
    const [status, setStatus] = useState('');
    const [email, setEmail] = useState('');
    const [interestStatus, setInterestStatus] = useState('');
    const analytics = useAnalytics('ContactUs');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/contact/enquire', formData);
            setStatus('success');
            setFormData({ email: '', message: '' });
        } catch (error) {
            console.error('Error submitting contact form:', error);
            setStatus('error');
        }
    };

    const handleInterestSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/interest/register-interest', { email });
            setInterestStatus('success');
            setEmail('');
            analytics.trackEvent('interest_registration', {
                status: 'success',
                source: 'contact_us_page'
            });
        } catch (error) {
            console.error('Error registering interest:', error);
            setInterestStatus('error');
        }
    };

    return (
        <div className="contact-us-page">
            <section className="register-interest-section">
                <h2>Join Our Growing Adventure!</h2>
                <p>We're expanding to new locations and bringing more magical woodland stories to life. Sign up to be the first to know about new locations and special offers.</p>
                <form onSubmit={handleInterestSubmit} className="register-interest-form">
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Keep Me Updated</button>
                </form>
                {interestStatus === 'success' && <p className="success-message">Successfully registered!</p>}
                {interestStatus === 'error' && <p className="error-message">Registration failed. Please try again.</p>}
            </section>

            <section className="contact-section">
                <h2>Contact Us</h2>
                <h3>Send us a message and we'll get back to you as soon as possible.</h3>
                <form onSubmit={handleSubmit} className="contact-us-form">
                    <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                    <textarea
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                    <button type="submit">Send Message</button>
                </form>
                {status === 'success' && <p className="success-message">Your message has been sent successfully!</p>}
                {status === 'error' && <p className="error-message">There was an error sending your message. Please try again.</p>}
            </section>
        </div>
    );
};

export default ContactUs;
