import React, { useEffect } from 'react';
import { BrowserRouter as Router , useLocation} from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout';
import AppRoutes from './Routes';
import { initializeAnalytics } from './utils/analytics';
import { CartProvider } from './contexts/CartContext';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  useEffect(() => {
    // Initialize enhanced analytics tracking
    initializeAnalytics();
  }, []);

  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <ScrollToTop />
          <Layout>
          <AppRoutes />
          </Layout>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
