import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import MapComponent from '../components/MapComponent';
import useAnalytics from '../hooks/useAnalytics';
import '../styles/MapPage.css';

// Move debounce outside component to prevent recreation
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const MapPage = () => {
    const [markers, setMarkers] = useState([]);
    const { auth } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [interestStatus, setInterestStatus] = useState('');
    const analytics = useAnalytics('MapPage');

    useEffect(() => {
        let isMounted = true;

        const fetchBaseStations = async () => {
            try {
                const response = await axios.get('/api/basestations');
                if (isMounted) {
                    const baseStations = response.data;
                    const newMarkers = baseStations.map(station => ({
                        lat: station.latitude,
                        lng: station.longitude,
                        tag: `${station.friendly_name}`
                    }));
                    setMarkers(newMarkers);
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching base stations:', error);
                }
            }
        };

        fetchBaseStations();
        return () => { isMounted = false; };
    }, []);

    const handleInterestSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/interest/register-interest', { email });
            setInterestStatus('success');
            setEmail('');
            analytics.trackEvent('interest_registration', {
                status: 'success',
                source: 'map_page'
            });
        } catch (error) {
            console.error('Error registering interest:', error);
            setInterestStatus('error');
        }
    };

    return (
        <div className="map-page">
            <div className="map-content">
                <h2 className="map-subtitle">Our Locations</h2>
                <div className="map-container">
                    <MapComponent 
                        markers={markers} 
                        center={[52.3555, -1.1743]} 
                        zoom={6}
                    />
                </div>
                <div className="location-counts">
                    <div className="location-count">
                        <h3> {markers.length}</h3>
                        <p>Base Stations</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapPage;
