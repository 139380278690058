// Analytics utility for frontend tracking
const generateSessionId = () => {
    const storedId = sessionStorage.getItem('analytics_session_id');
    if (storedId) return storedId;
    
    const newId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    sessionStorage.setItem('analytics_session_id', newId);
    return newId;
};

const sendAnalyticsEvent = async (eventData) => {
    try {
        const sessionId = generateSessionId();
        
        await fetch('/api/analytics/track', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...eventData,
                sessionId
            })
        });
    } catch (error) {
        console.error('Failed to send analytics:', error);
    }
};

export const initializeAnalytics = () => {
    // Track all button and link clicks
    document.addEventListener('click', (e) => {
        const element = e.target.closest('button, a, [role="button"]');
        if (element) {
            const elementType = element.tagName.toLowerCase();
            const elementId = element.id || element.href || 'unnamed';
            trackInteraction('click', elementId, {
                elementType,
                elementText: element.textContent?.trim() || '',
                elementHref: element.href || null
            });
        }
    });

    // Track page exits
    window.addEventListener('beforeunload', () => {
        const sessionId = sessionStorage.getItem('analytics_session_id');
        if (sessionId) {
            // Use sendBeacon for more reliable tracking on page exit
            const data = {
                type: 'pageview',
                sessionId,
                isExit: true,
                timestamp: new Date().toISOString(),
                url: window.location.pathname
            };
            navigator.sendBeacon('/api/analytics/track', JSON.stringify(data));
        }
    });

    // Initialize session
    if (!sessionStorage.getItem('analytics_session_id')) {
        generateSessionId();
        trackPageView(window.location.pathname);
    }
};

export const trackPageView = async (pageName) => {
    const sessionId = generateSessionId();
    const isFirstPageView = !sessionStorage.getItem('page_views');
    const pageViews = parseInt(sessionStorage.getItem('page_views') || '0') + 1;
    sessionStorage.setItem('page_views', pageViews.toString());

    await sendAnalyticsEvent({
        type: 'pageview',
        page: pageName,
        timestamp: new Date().toISOString(),
        url: window.location.pathname,
        referrer: document.referrer,
        userAgent: navigator.userAgent,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        language: navigator.language,
        sessionStartTime: isFirstPageView ? new Date().toISOString() : undefined,
        isBounce: isFirstPageView
    });
};

export const trackEvent = (eventName, eventData = {}) => {
    sendAnalyticsEvent({
        type: 'event',
        event: eventName,
        ...eventData,
        timestamp: new Date().toISOString(),
        url: window.location.pathname
    });
};

export const trackError = (error, componentName) => {
    sendAnalyticsEvent({
        type: 'error',
        error: error.message,
        component: componentName,
        stack: error.stack,
        timestamp: new Date().toISOString(),
        url: window.location.pathname
    });
};

export const trackInteraction = (interactionType, elementId, additionalData = {}) => {
    sendAnalyticsEvent({
        type: 'interaction',
        interaction: interactionType,
        element: elementId,
        ...additionalData,
        timestamp: new Date().toISOString(),
        url: window.location.pathname
    });
};
