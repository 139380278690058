import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VersionUpload = () => {
  const [tag, setTag] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await axios.get('/api/versions', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setVersions(response.data);
      } catch (error) {
        console.error('Error fetching versions:', error);
      }
    };

    fetchVersions();
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!tag || !file) {
      setMessage('Tag and file are required.');
      return;
    }

    const formData = new FormData();
    formData.append('tag', tag);
    formData.append('versionZip', file);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      await axios.post('/api/versions/upload', formData, config);
      setMessage('Version uploaded successfully.');
      setTag('');
      setFile(null);
      // Fetch the updated list of versions
      const response = await axios.get('/api/versions', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setVersions(response.data);
    } catch (error) {
      setMessage(error.response?.data?.message || 'Upload failed.');
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this version?')) return;
    try {
      await axios.delete(`/api/versions/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setMessage('Version deleted successfully.');
      // Fetch the updated list of versions
      const response = await axios.get('/api/versions', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setVersions(response.data);
    } catch (error) {
      setMessage(error.response?.data?.message || 'Delete failed.');
    }
  };

  const handleDownload = (id) => {
    window.location.href = `/api/versions/download/${id}`;
  };

  return (
    <div className="version-upload">
      <h3>Upload New Version</h3>
      {message && <p>{message}</p>}
      <form onSubmit={handleUpload}>
        <div>
          <label>Version Tag:</label>
          <input
            type="text"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Zip File:</label>
          <input
            type="file"
            accept=".zip"
            onChange={(e) => setFile(e.target.files[0])}
            required
          />
        </div>
        <button type="submit">Upload Version</button>
      </form>
      <h3>Available Versions</h3>
      <ul>
        {versions.map(version => (
          <li key={version._id}>
            {version.tag} - {new Date(version.uploadedAt).toLocaleString()}
            <button onClick={() => handleDownload(version._id)}>Download</button>
            <button onClick={() => handleDelete(version._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VersionUpload;