import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cart from './Cart';
import { CartContext } from '../contexts/CartContext';
import '../styles/Layout.css';

const Layout = ({ children }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isCartOpen, toggleCart } = useContext(CartContext);

  const isHomePage = location.pathname === '/';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToMain = () => {
    window.scrollTo({
      top: window.innerHeight * 0.9, // Scroll past hero section
      behavior: 'smooth'
    });
  };

  return (
    <div className={`layout ${isHomePage ? 'no-background' : ''}`}>
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/">Woodland Stories</Link>
        </div>
        <div className="navbar-right">
          <div className="navbar-hamburger" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
            <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
            <li><Link to="/shop" onClick={toggleMenu}>Shop</Link></li>
            <li><Link to="/map" onClick={toggleMenu}>Map</Link></li>
            <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
          </ul>
          <div className="navbar-cart" onClick={toggleCart}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <path d="M16 10a4 4 0 0 1-8 0"></path>
            </svg>
          </div>
        </div>
      </nav>
      <div className={`cart-dropdown ${isCartOpen ? 'open' : ''}`}>
        <Cart />
      </div>
      {isHomePage && (
        <button onClick={scrollToMain} className="learn-more-btn">
          Learn More
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M7 13l5 5 5-5"/>
            <path d="M7 6l5 5 5-5"/>
          </svg>
        </button>
      )}
      <div className="content">
        {children}
      </div>
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; Woodland Stories Co. {new Date().getFullYear()}</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
